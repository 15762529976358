import { cloneDeep } from 'lodash';

export function mergeFeatureDataAndLayerConfig(
  mainResult,
  yearlyResult,
  scenarioYear,
  panelConfig,
  scenarioName,
  options,
) {
  const year = scenarioYear;
  const panelData = cloneDeep(panelConfig);
  let yearlyValues = {};

  if (typeof yearlyResult !== 'undefined' && yearlyResult !== null) {
    yearlyValues = yearlyResult.find((e) => e.year === year);
  }
  let feature;

  feature = { ...mainResult, ...yearlyValues };

  panelData.subtitles.forEach((subtitle, index1) => {
    const option = options[subtitle];
    let value = feature[subtitle];
    panelData.subtitles[index1] = { subtitle, value, ...option };
  });

  if (scenarioName != null) {
    panelData.subtitles.unshift({
      label: 'Szenario',
      value: scenarioName + ' ' + scenarioYear,
    });
  }

  // add chips
  panelData.chips.forEach((chip, index1) => {
    chip.items.forEach((category, index2) => {
      category.items.forEach((item, index3) => {
        const key = item.key;
        const field = panelData.chips[index1].items[index2].items[index3];

        if (
          field.panelItemType === 'pieChart' &&
          Object.hasOwn(field, 'customDataTransformFunction')
        ) {
          field.value = item.customDataTransformFunction(feature);
        }

        if (typeof feature[key] !== 'undefined') {
          const option = options[key];

          let value = feature[key];

          if (option.type === 'choice') {
            if (typeof value === 'undefined' || value === null) {
              field.value = '-';
            } else {
              field.value = option.choices.find(
                (e) => e.value === value,
              ).display_name;
            }
          } else if (
            option.type === 'list' ||
            (option.type === 'field' && typeof field.value === 'number') ||
            field.panelItemType === 'pieChart'
          ) {
            field.value = value;
          } else if (option.type === 'boolean') {
            value ? (field.value = 'Ja') : (field.value = 'Nein');
          } else {
            field.value = formatNumber(item, value);
          }
          // merge options into field object
          panelData.chips[index1].items[index2].items[index3] = {
            ...field,
            ...option,
          };
        }
      });
    });
  });
  return cleanPanelData(panelData);
}

export function cleanPanelData(panelData) {
  const cleanedData = { ...panelData };
  cleanedData.chips = [];
  panelData.chips.forEach((chip) => {
    const expandables = [];
    chip.items.forEach((expandable) => {
      expandable.items = expandable.items.filter(
        (i) => i.value !== undefined || i.panelItemType,
      );
      if (expandable.items.length > 0) expandables.push(expandable);
    });
    chip.items = expandables;
    if (expandables.length > 0) {
      cleanedData.chips.push(chip);
    }
  });
  if (cleanedData.chips.length === 1) cleanedData.chips[0].active = true;
  return cleanedData;
}

function formatNumber(item, value) {
  if (value === null) return '-';
  if (typeof value === 'string' || value instanceof String || item.forceString)
    return `${value}`;
  return (item.multiply ? value * item.multiply : value).toLocaleString(
    'de-DE',
    {
      maximumFractionDigits: item.decimal,
      minimumFractionDigits: item.decimal,
    },
  );
}
