<template>
  <VDialog
    v-model="mapActionsDialog"
    width="auto"
    :no-click-animation="true"
    scrim="#263238"
    scrim-opacity="0.2"
    transition="fade-transition"
  >
    <template #activator="{ props: launcherProps }">
      <div
        v-if="Object.keys(mapStore.mapActions).length"
        v-bind="launcherProps"
        class="standard-elevation-2 rounded-[8px]"
      >
        <ButtonEl
          data-test="grid-icon"
          icon="apps"
          variant="primary"
          color="color2"
          class="rounded-[8px] h-10 w-10"
        />
      </div>
    </template>
    <div
      class="grid grid-flow-col auto-cols-fr gap-0.5 rounded-[8px] standard-elevation-2 overflow-hidden"
    >
      <template
        v-for="[key, item] in Object.entries(mapStore.mapActions)"
        :key="item"
      >
        <div
          :class="getClass(item)"
          class="w-36 h-36 flex flex-col gap-3 items-center justify-start p-4 pt-7"
          @click="setAction(key)"
        >
          <IconWrapper
            :icon="item.icon"
            :size="44"
            :type="item.icon === 'account_tree' ? 'rounded' : 'round'"
            fill="text-title-color1"
            class="pointer-events-none"
          />
          <h6 class="text-neutral text-center whitespace-pre-wrap">
            {{ item.title }}
          </h6>
        </div>
      </template>
    </div>
  </VDialog>
</template>

<script setup>
import { ref } from 'vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';

const props = defineProps({
  mapStore: {
    type: Object,
    required: true,
  },
});

const mapActionsDialog = ref(false);

function setAction(id) {
  props.mapStore.setMapAction(id);
  mapActionsDialog.value = false;
}

function getClass(item) {
  return item.disabled
    ? 'pointer-events-none bg-white'
    : (item.active ? 'bg-blue-grey-50' : 'bg-white hover:bg-blue-grey-50') +
        ' cursor-pointer';
}
</script>
