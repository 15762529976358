<template>
  <div class="flex flex-col gap-5">
    <div v-show="!readOnly" :class="[{ flex: !readOnly }, 'flex-col gap-5']">
      <div class="flex flex-col gap-2.5">
        <div class="button-1 text-title-neutral">Restriktionen einstellen</div>
        <div
          class="flex items-end gap-2.5 standard-elevation-0-dark p-5 rounded-lg"
        >
          <CheckboxEl v-model="distanceCheckbox" class="mb-3" />
          <InputEl
            v-model="buffer"
            label="Abstand"
            input-type="integer"
            placeholder="Bitte eingeben"
            class="w-[400px]"
            suffix="m"
            :disabled="!distanceCheckbox"
          />

          <div class="flex h-fit items-center gap-1.5 mb-1.5">
            <CheckboxEl
              v-model="stateCheckbox"
              @update:model-value="updateState(true)"
            />
            <div class="flex">
              <ButtonEl
                :disabled="!stateCheckbox"
                text="AKTIV"
                color="success"
                :class="{
                  'bg-white text-title-neutral':
                    !restrictionIsActive || !stateCheckbox,
                }"
                size="m"
                @click="updateState(true)"
              />
              <ButtonEl
                :disabled="!stateCheckbox"
                text="INAKTIV"
                color="warning"
                :class="{
                  'bg-white text-title-neutral':
                    restrictionIsActive || !stateCheckbox,
                }"
                size="m"
                @click="updateState(false)"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="flex gap-5 justify-between items-end min-h-[54px] pl-3">
        <ButtonEl
          text="Eingabe für folgende Subkategorien anwenden"
          icon="assignment_returned"
          color="color2"
          @click="updateDistance"
        />
        <div class="flex gap-2.5 items-end">
          <div
            v-show="showFilters"
            class="items-end gap-2.5"
            :class="{ flex: showFilters }"
          >
            <DropDownMultiple
              v-model="categoryFilter"
              label="Filter nach Kategorie"
              item-type-all="Kategorien"
              all-selected-label="Alle"
              class="w-[312px]"
              :items="categoriesList"
              data-key="name"
              value-key="restriction_category"
              placeholder="Bitte auswählen"
              :init-select-all="true"
            />
            <DropDownMultiple
              v-model="subCategoryFilter"
              label="Filter nach Subkategorie"
              item-type-all="Subkategorien"
              all-selected-label="Alle"
              class="w-[312px]"
              :items="subcategoriesList"
              data-key="name"
              value-key="restriction_sub_category"
              placeholder="Bitte auswählen"
              :init-select-all="true"
            />
          </div>
          <ButtonEl
            icon="filter_alt"
            color="color2"
            icon-type="round"
            class="w-10 h-10"
            @click="showFilters = !showFilters"
          />
        </div>
      </div>
    </div>
    <VuetifyDataTable
      :headers="tableHeadersRestrictions"
      :item-data="filteredRestrictions"
      :show-expand="false"
      :header-small="true"
      item-value="restriction_sub_category"
      :show-select="true"
      :read-only
      :select-all-checkboxes="true"
      :open-page-options-to-top="true"
      @selected-rows="selectedCategories = $event"
    >
      <template #restriction_category="{ item }">
        {{ categoriesNamingMap[item.restriction_category] }}
      </template>
      <template #restriction_sub_category="{ item }">
        {{ subCategoriesNamingMap[item.restriction_sub_category] }}
      </template>
      <template #buffer="{ item }">
        <SkeletonLoader
          v-if="item.buffer === null || item.buffer === undefined || showLoader"
        />
        <span v-else>
          {{
            item.buffer.toLocaleString('de-DE', {
              maximumFractionDigits: 0,
            }) + ' m'
          }}
        </span>
      </template>
      <template #use_sub_category="{ item }">
        <SkeletonLoader
          v-if="
            item.use_sub_category === null ||
            item.use_sub_category === undefined ||
            showLoader
          "
        />
        <ButtonEl
          v-else
          :text="item.use_sub_category ? 'AKTIV' : 'INAKTIV'"
          size="m"
          :color="item.use_sub_category ? 'success' : 'warning'"
        />
      </template>
    </VuetifyDataTable>
  </div>
</template>

<script setup>
import ButtonEl from '@/components/button/ButtonEl.vue';
import CheckboxEl from '@/components/CheckboxEl/CheckboxEl.vue';
import DropDownMultiple from '@/components/dropDownMultiple/DropDownMultiple.vue';
import InputEl from '@/components/input/InputEl.vue';
import VuetifyDataTable from '@/components/vuetifyDataTable/VuetifyDataTable.vue';
import { useRestrictionsStore } from './stores/potentialAnalysisRestrictionsStore';
import { storeToRefs } from 'pinia';
import { computed, ref, watch, watchEffect } from 'vue';
import { useVModel } from '@vueuse/core';
import SkeletonLoader from '@/components/skeletonLoaderItem/skeletonLoader.vue';

const props = defineProps({
  readOnly: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);
const analysis = useVModel(props, 'modelValue', emit);

const distanceCheckbox = ref(false);
const stateCheckbox = ref(false);
const restrictionIsActive = ref(true);

const buffer = ref(null);

function updateState(state) {
  restrictionIsActive.value = state;
}

const tableHeadersRestrictions = computed(() => [
  {
    title:
      'Kategorie' + ' (' + Object.keys(categoriesNamingMap.value).length + ')',
    key: 'restriction_category',
    width: '150px',
  },
  {
    title:
      'Subkategorie' +
      ' (' +
      Object.keys(subCategoriesNamingMap.value).length +
      ')',
    key: 'restriction_sub_category',
    width: '125px',
  },
  {
    title: 'Abstand',
    key: 'buffer',
    width: '125px',
  },
  {
    title: 'Status',
    key: 'use_sub_category',
    width: '125px',
  },
]);

const { categoriesNamingMap, subCategoriesNamingMap, categoriesData } =
  storeToRefs(useRestrictionsStore());

function updateSubCategoriesFromAnalysis() {
  const map = analysis.value.configrestrictions_set.reduce((acc, obj) => {
    acc[obj.restriction_sub_category] = obj;
    return acc;
  }, {});
  for (const subCategory of categoriesData.value) {
    const fromAnalysis = map[subCategory.restriction_sub_category];
    subCategory.buffer = fromAnalysis?.buffer ?? 0;
    subCategory.use_sub_category = fromAnalysis?.use_sub_category ?? false;
  }
}

watchEffect(() => {
  updateSubCategoriesFromAnalysis();
});

const showFilters = ref(false);
const categoryFilter = ref([]);
const subCategoryFilter = ref([]);
const selectedCategories = ref(
  categoriesData.value.map((item) => item.restriction_sub_category),
);

const filteredRestrictions = computed(() => {
  const filterObject = toObject(categoryFilter.value, 'restriction_category');
  const selectedSubCategories = new Set(
    subCategoryFilter.value.map((item) => item.restriction_sub_category),
  );

  return categoriesData.value.filter((item) => {
    const categoryMatch = filterObject[item.restriction_category];
    const subCategoryMatch = selectedSubCategories.has(
      item.restriction_sub_category,
    );

    return (
      categoryMatch && (selectedSubCategories.size === 0 || subCategoryMatch)
    );
  });
});

function toObject(arr, key) {
  return arr.reduce((result, item) => {
    return { ...result, [item[key]]: true };
  }, {});
}

const categoriesList = computed(() => {
  return Object.entries(categoriesNamingMap.value).map(
    ([restriction_category, name]) => ({
      restriction_category,
      name,
    }),
  );
});

const subcategoriesList = computed(() => {
  return Object.entries(subCategoriesNamingMap.value).map(
    ([restriction_sub_category, name]) => ({
      restriction_sub_category,
      name,
    }),
  );
});

function updateDistance() {
  const idsSet = new Set(selectedCategories.value);

  categoriesData.value.forEach((item) => {
    if (idsSet.has(item.restriction_sub_category)) {
      if (buffer.value !== null) {
        item.buffer = buffer.value;
      }
      if (stateCheckbox.value) {
        item.use_sub_category = restrictionIsActive.value;
      }
    }
  });
}

function setRestrictions() {
  analysis.value.configrestrictions_set = categoriesData.value.map((item) => ({
    restriction_category: item.restriction_category,
    restriction_sub_category: item.restriction_sub_category,
    buffer: item.buffer,
    use_sub_category: item.use_sub_category,
  }));
}

watchEffect(() => {
  setRestrictions();
});

const showLoader = ref(false);
let loaderTimeout; // Declare a variable to hold the timeout ID

// everytime the analysis changes, show the loader for 400ms and then reset the loader
watch(
  () => analysis.value.configrestrictions_set,
  () => {
    showLoader.value = true;
    clearTimeout(loaderTimeout); // Clear any existing timer
    loaderTimeout = setTimeout(() => {
      showLoader.value = false;
    }, 400);
  },
  { deep: true },
);
</script>
