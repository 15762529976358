<template>
  <div
    v-if="mapActionActive"
    ref="mapActionsContainer"
    :class="[
      'flex rounded-md fixed',
      isDragging ? 'standard-elevation-0-dark' : 'standard-elevation-2',
    ]"
    :style="style"
  >
    <ButtonEl
      icon="drag_indicator"
      color="color2"
      class="rounded-l-md rounded-none w-6 h-10 border-r border-line-disabled-neutral"
    />
    <MapActionSingle
      v-for="(button, index) in mapActionActive.buttons"
      :key="button.id"
      :icon="button.icon"
      :tool-tip="button.toolTip"
      :active="button.drawMode === drawModeActive"
      :content-classes="
        index === mapActionActive.buttons.length - 1
          ? 'rounded-r-md rounded-l-none'
          : 'rounded-none'
      "
      @click="button.click(draw, button)"
    />
  </div>
</template>

<script setup>
import { ref, toRefs, watch, nextTick } from 'vue';
import { useDraggable, useElementBounding, useWindowSize } from '@vueuse/core';
import MapActionSingle from './MapActionSingle.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import { draw } from '../../draw/draw';

const props = defineProps({
  mapStore: {
    type: Object,
    required: true,
  },
});

const { mapActionActive, drawModeActive } = toRefs(props.mapStore);

const mapActionsContainer = ref(null);
const { x, y, style, isDragging } = useDraggable(mapActionsContainer);
const { width, height } = useWindowSize();

function updatePosition() {
  const { width: containerWidth } = useElementBounding(mapActionsContainer);
  x.value = (width.value - containerWidth.value) / 2;
  y.value = height.value - 160;
}

watch(
  [width, height, mapActionActive],
  async () => {
    if (!mapActionActive.value) return;
    await nextTick();
    updatePosition();
  },
  { immediate: true },
);
</script>
