<template>
  <div class="flex flex-col gap-4 w-full">
    <div
      v-if="showTabHeaders"
      class="border-b-[6px] border-[#968F89]"
      :class="{ 'mx-2': !hideTabWindows }"
    >
      <VTabs
        v-model="activeTab"
        :items="filteredTabs"
        class="mb-[-6px] text-neutral"
        density="comfortable"
        :slider-color="activeColor"
      >
        <template #tab="{ item: tabItem }">
          <VTab
            :value="tabItem"
            class="px-5 normal-case tracking-normal"
            :ripple="false"
          >
            <div
              class="flex gap-2 items-center justify-center"
              :class="
                activeTab.title === tabItem.title
                  ? 'text-button-primary-default-color1'
                  : 'text-neutral'
              "
            >
              <IconWrapper
                v-if="tabItem.icon"
                :icon="tabItem.icon"
                :size="16"
                :type="tabItem.iconType"
                class="relative bottom-[1px]"
              />
              <div class="subtitle-2">
                {{ tabItem.title }}
              </div>
            </div>
          </VTab>
        </template>
      </VTabs>
    </div>
    <VTabsWindow
      v-if="!hideTabWindows"
      v-model="activeTab.title"
      :class="customWindowClasses"
      :style="computedStyle"
    >
      <template v-for="(tabContent, index) in tabContents" :key="index">
        <VTabsWindowItem
          :transition="!disableTransitions"
          :reverse-transition="!disableTransitions"
          :value="tabContent.value"
          class="vtab-item-custom"
          :eager
        >
          <slot :name="tabContent.slotName" />
        </VTabsWindowItem>
      </template>
    </VTabsWindow>
  </div>
</template>

<script setup>
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { computed, nextTick, ref, watch } from 'vue';

const props = defineProps({
  tabs: {
    type: Array,
    required: true,
  },
  showTabHeaders: {
    type: Boolean,
    default: true,
  },
  customWindowClasses: {
    type: String,
    default: '',
  },
  disableTransitions: {
    type: Boolean,
    default: false,
  },
  eager: {
    type: Boolean,
    default: false,
  },
  tabsWindowHeightSubstract: {
    type: Number,
    default: 330,
  },
  maxHeightIsHeight: {
    type: Boolean,
    default: false,
  },
  preventWindowScroll: {
    type: Boolean,
    default: false,
  },
  hideTabAtIndex: {
    type: [Number, Array],
    default: null,
  },
  hideTabWindows: {
    type: Boolean,
    default: false,
  },
});

defineExpose({ setActiveTab });

const style = getComputedStyle(document.documentElement);
const activeColor = `rgb(${style.getPropertyValue('--button-primary-default-color1')})`;

const activeTab = ref(props.tabs[0]);

function setActiveTab(tab = null) {
  if (tab) {
    activeTab.value = tab;
  } else if (activeTab.value !== props.tabs[0]) {
    activeTab.value = props.tabs[0];
  }
}

const tabContents = computed(() => {
  // Check if hideTabAtIndex is an array or a single number
  const hiddenIndexes = Array.isArray(props.hideTabAtIndex)
    ? props.hideTabAtIndex
    : [props.hideTabAtIndex];

  return Array.from({ length: props.tabs.length }, (_, index) => {
    const slotName = `slot${String(index)}`;
    return {
      value: props.tabs[index].title,
      slotName: slotName,
      isVisible: !hiddenIndexes.includes(index),
    };
  }).filter((tab) => tab.isVisible);
});

const emit = defineEmits(['updated:tab']);

// watch activeTab and emit event
watch(
  activeTab,
  async (newVal) => {
    emit('updated:tab', newVal);

    await nextTick();

    if (!props.preventWindowScroll) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    }
  },
  { immediate: true },
);

const filteredTabs = computed(() => {
  // Check if hideTabAtIndex is an array or a single number
  const hiddenIndexes = Array.isArray(props.hideTabAtIndex)
    ? props.hideTabAtIndex
    : [props.hideTabAtIndex];

  return props.tabs.filter((_, index) => !hiddenIndexes.includes(index));
});

const computedStyle = computed(() => {
  const heightValue = `calc(100vh - ${props.tabsWindowHeightSubstract}px)`;
  const height = props.maxHeightIsHeight ? heightValue : '100%';

  return {
    height,
    maxHeight: height,
  };
});
</script>

<style lang="scss" scoped>
.vtab-item-custom {
  overflow-y: auto;
  padding: 8px;
  height: 100%;
}
</style>
