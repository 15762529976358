import { LAYER_KEY__ESTATE } from '@/apps/usecase-2/map/layer-config/base-data';
import { LAYER_KEY__EXTERNAL_PROJECT } from '@/apps/usecase-2/map/layer-config/external-project';

export const layerPanelConfig = {
  sections: [
    {
      title: 'Basisdaten',
      groups: [
        {
          title: 'Gebäudestruktur',
          id: 'buildingStructure',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeBuildingToggle: true,
                includeAggregationToggle: {
                  partial: false,
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Flurstücke',
          id: 'estateStructure',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__ESTATE,
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Umweltdaten',
          id: 'environmentalData',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Restriktionsflächen',
          id: 'restrictions',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Besondere Flächenqualifikation',
          id: 'areaQuality',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Landnutzung',
          id: 'landUse',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Straßen und Wege',
          id: 'roads',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Planhinweiskarte',
          id: 'planMap',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Bestandsanalagen',
          id: 'existingPowerPlant',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                },
              },
              layerState: 'default',
            },
          ],
        },
      ],
      show: true,
    },
    {
      title: 'Infrastruktur',
      groups: [
        {
          title: 'Mittelspannung ',
          id: 'mediumVoltageGrid',
          items: [
            {
              title: 'keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                  icon: 'shape_line',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Hochspannungsnetz ',
          id: 'highVoltageGrid',
          items: [
            {
              title: 'keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                  icon: 'shape_line',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Netzausbau',
          id: 'gridPlanning',
          items: [
            {
              title: 'keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                  icon: 'shape_line',
                },
              },
              layerState: 'default',
            },
          ],
        },
      ],
      show: true,
    },
    {
      title: 'Potenzialanalyse',
      groups: [
        {
          title: 'Potenzialfläche',
          id: 'potentialArea',
          items: [
            {
              title: 'keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                  icon: 'shape_line',
                },
              },
              layerState: 'default',
            },
            {
              title: 'Flächengröße',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                  icon: 'shape_line',
                },
              },
              layerState: 'area',
            },
            {
              title: 'Leistung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                  icon: 'shape_line',
                },
              },
              layerState: 'power',
            },
            {
              title: 'Investitionsvolumen',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                  icon: 'shape_line',
                },
              },
              layerState: 'invest',
            },
          ],
        },
        {
          title: 'Betroffene Flurstücke',
          id: 'potentialEstates',
          items: [
            {
              title: 'keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                  icon: 'shape_line',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Umspannstation',
          id: 'highVoltageGrid',
          items: [
            {
              title: 'keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                  icon: 'shape_line',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Netzanbindung',
          id: 'gridPlanning',
          items: [
            {
              title: 'keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                  icon: 'shape_line',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Netzverknüpfungspunkt',
          id: 'gridPlanning',
          items: [
            {
              title: 'keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                  icon: 'shape_line',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Externe Projekte',
          id: 'externalProjects',
          items: [
            {
              title: 'keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__EXTERNAL_PROJECT,
                  icon: 'shape_line',
                  dataFromStoreAction: 'fetchAndCacheExternalProjects',
                },
              },
              layerState: 'default',
            },
          ],
        },
      ],
      show: true,
    },
  ],
};
