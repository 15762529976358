<template>
  <div
    :class="{ 'left-5': !getMainMenuShow, 'left-[400px]': getMainMenuShow }"
    class="bg-white rounded-[8px] top-[80px] h-fit w-[360px] absolute z-10 p-2.5 flex flex-col gap-2.5 standard-elevation-2 overflow-y-auto hide-scrollbar"
  >
    <h5 class="text-title-neutral">Projektcluster erstellen</h5>

    <InputEl
      v-model="name"
      label="Bezeichnung"
      placeholder="Bitte eingeben"
      data-test="location-name"
    />

    <div
      class="flex flex-col gap-2.5 standard-elevation-0-dark p-2.5 rounded-[6px]"
    >
      <h5 class="text-color1 flex items-center">Einzelprojekte selektieren</h5>

      <div
        class="text-neutral flex flex-col items-center gap-0.5 standard-elevation-0-dark rounded-xs p-1.5"
      >
        <span class="caption-1">Anzahl Einzelprojekte</span>
        <h6>
          {{ countSelectedProjects }}
        </h6>
      </div>

      <div
        class="text-neutral flex flex-col items-center gap-0.5 standard-elevation-0-dark rounded-xs p-1.5"
      >
        <span class="caption-1">Summe Anschlussleistung</span>
        <h6>
          {{
            mapStore.clusterProjectSelectedPower
              ? mapStore.clusterProjectSelectedPower.toLocaleString('de-DE')
              : 0
          }}
          MW
        </h6>
      </div>
    </div>
    <div
      class="flex flex-col gap-2.5 standard-elevation-0-dark p-2.5 rounded-[6px]"
    >
      <h5 class="text-color1 flex items-center">Umspannwerke allokieren</h5>
      <div
        class="text-neutral flex flex-col items-center gap-0.5 standard-elevation-0-dark rounded-xs p-1.5"
      >
        <span class="caption-1">Anzahl Umspannwerke</span>
        <h6>
          {{
            mapStore.clusterSubstationPointsDraw.length.toLocaleString('de-DE')
          }}
        </h6>
      </div>
    </div>
    <VRadioGroup v-model="route">
      <div class="flex justify-start">
        <VRadio value="PV">
          <template #label
            >Geringste Netzanschlusskosten gemäß Trassenkategorien</template
          >
        </VRadio>
        <VRadio value="WIND" class="ml-2">
          <template #label>Kürzester Weg</template>
        </VRadio>
      </div>
    </VRadioGroup>
    <div class="grid grid-cols-2 gap-2.5">
      <ButtonEl
        :full-width="true"
        color="color2"
        text="Abbrechen"
        @click="abort()"
      />

      <ButtonEl
        :full-width="true"
        text="Jetzt Berechnen"
        :disabled="!name"
        class="pr-4"
        @click="save()"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { draw } from '@/apps/features/map/draw/draw';
import InputEl from '@/components/input/InputEl.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import { useMapStore } from '@/apps/usecase-2/map/map-store';

const mapStore = useMapStore();

const name = ref();
const route = ref();

const getMainMenuShow = computed(() => mapStore.showMainMenu);
const countSelectedProjects = computed(() => {
  const projects = mapStore.clusterProjectIdsSelected.size || 0;
  const externalProjects = mapStore.clusterExternalProjectIdsSelected.size || 0;
  return (projects + externalProjects).toLocaleString('de-DE');
});

function save() {
  console.log('TODO: add dto and save action');
}

function abort() {
  mapStore.resetMapAction(draw);
  // mapStore.$reset()
}
</script>
