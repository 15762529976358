<template>
  <div class="flex flex-col gap-5">
    <InvestIntervalSingle
      ref="pvIntervalComp"
      title="PV-Anlage"
      :create-value="{
        fieldName: 'investment_cost_eur_kw',
        title: '',
        unit: '€/kW',
      }"
      default-value="800"
      :intervals="intervalPv"
      @add-interval="addInterval(intervalPv, $event)"
      @remove-interval="removeInterval(intervalPv)"
      @update-error="error = $event"
    />
    <InvestIntervalSingle
      ref="transformerIntervalComp"
      title="Umspannwerk"
      :create-value="{
        fieldName: 'investment_cost_eur_kw',
        title: '',
        unit: '€/kW',
      }"
      default-value="160"
      :intervals="intervalTransformer"
      @add-interval="addInterval(intervalTransformer, $event)"
      @remove-interval="removeInterval(intervalTransformer)"
      @update-error="error = $event"
    />
    <InvestIntervalSingle
      ref="cableIntervalComp"
      title="Netzanbindung Kabel"
      :create-value="{
        fieldName: 'investment_cost_eur_m',
        title: '',
        unit: '€/m',
      }"
      default-value="160"
      :intervals="intervalCable"
      @add-interval="addInterval(intervalCable, $event)"
      @remove-interval="removeInterval(intervalCable)"
      @update-error="error = $event"
    />
    <div
      class="standard-elevation-0-dark rounded-[12px] p-5 flex flex-col gap-5"
    >
      <div class="flex items-center gap-2">
        <h5 class="text-color1">Batteriespeicher</h5>
        <ToolTip>
          <template #text
            >Für die Auslegung des Batteriespeichers wird ein Standardmodul
            genutzt, welches entsprechend der Auslegungsleistung skaliert
            wird.</template
          >
        </ToolTip>
      </div>
      <InputEl
        v-model="batteryInvestPerModule"
        label="Investitionskosten je Modul"
        input-type="float"
        placeholder="Bitte eingeben"
        suffix="€"
        class="w-1/4"
      />
      <div class="flex justify-start items-end gap-1.5">
        <InputEl
          v-model="batteryKw"
          label="Bei Standard-Modulgröße"
          input-type="float"
          placeholder="Bitte eingeben"
          suffix="kW"
          class="w-1/4"
        />
        <InputEl
          v-model="batteryKwh"
          label=""
          input-type="float"
          placeholder="Bitte eingeben"
          suffix="kWh"
          class="w-1/4"
        />
      </div>
    </div>

    <div
      class="standard-elevation-0-dark rounded-[12px] p-5 flex flex-col gap-5"
      :class="{ 'outline outline-error': error }"
    >
      <ExpansionPanel
        :panels="['Trassenkategorien']"
        :show-circle-number="false"
        :first-panel-open="false"
        :eager="true"
      >
        <template #panel1>
          <InvestIntervalMulti
            ref="routeIntervalComp"
            title="Trassenkategorien"
            :intervals="intervalRoute"
            :categories="routeCategories"
            group-title="Alle Trassenkategorien"
            category-field-name="route_category"
            :create-value="{
              fieldName: 'investment_cost_eur_m',
              unit: '€/m',
            }"
            default-value="160"
            @add-interval="addInterval(intervalRoute, $event)"
            @remove-interval="removeInterval(intervalRoute)"
            @update-error="error = $event"
          />
        </template>
      </ExpansionPanel>
      <div class="flex justify-start gap-3">
        <InputEl
          v-model="singleValueForRoutes"
          label="Tiefbau pauschal"
          :input-type="'float'"
          :disabled="!useSingleValueForRoutes"
          placeholder="Bitte eingeben"
          suffix="€/m"
          class="w-1/4"
        />
        <CheckboxEl v-model="useSingleValueForRoutes" class="mt-3" />
      </div>
    </div>
    <div class="flex gap-5">
      <ButtonEl
        icon="save"
        icon-fill="text-inverted-color1"
        text="Speichern"
        class="w-fit"
        :disabled="error !== null"
        @click="saveDialog = true"
      />
      <ButtonEl
        icon="lock_reset"
        color="color2"
        variant="secondary"
        text="Zurücksetzen auf Default"
        class="w-fit"
        @click="console.log('define usecase')"
      />
    </div>
    <VuetifyDialog
      v-if="saveDialog"
      v-model="saveDialog"
      title="Hinweis"
      :persistent="true"
      width="620px"
    >
      <template #content>
        Die angepassten Einstellungen wirken sich auf alle neuen Projekte aus.
        Bestehende Projekte bleiben unverändert.
      </template>
      <template #actions>
        <ButtonEl text="Abbrechen" color="color2" @click="saveDialog = false" />
        <ButtonEl
          text="Speichern"
          :disabled="isCreating"
          :is-loading="isCreating"
          @click="save()"
        />
      </template>
    </VuetifyDialog>
  </div>
</template>

<script setup>
import InvestIntervalMulti from '@/apps/usecase-2/settings/invest-costs/InvestIntervalMulti.vue';
import InvestIntervalSingle from '@/apps/usecase-2/settings/invest-costs/InvestIntervalSingle.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import CheckboxEl from '@/components/CheckboxEl/CheckboxEl.vue';
import ExpansionPanel from '@/components/ExpansionPanel/ExpansionPanel.vue';
import InputEl from '@/components/input/InputEl.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import axios from '@/utils/axiosHelper';
import { onMounted, ref } from 'vue';
import cookie from 'vue-cookies';
import { useToastStore } from '@/stores/toast-store';

const { showToast } = useToastStore();

const useSingleValueForRoutes = ref(false);
const singleValueForRoutes = ref(150);
const error = ref();

const saveDialog = ref(false);
const isCreating = ref(false);

const routeCategoriesData = await axios({
  method: 'get',
  url: '/api/usecase-2/route-categories/',
});
const routeCategories = ref(
  routeCategoriesData.data.categories.map((e) => ({
    choice: e[0],
    label: e[1],
  })),
);

const batteryInvestPerModule = ref(3500000);
const batteryKw = ref(6000);
const batteryKwh = ref(6700);

// ---------- interval logic
const intervalPv = ref([]);
const intervalTransformer = ref([]);
const intervalCable = ref([]);
const intervalRoute = ref([]);

const pvIntervalComp = ref();
const transformerIntervalComp = ref();
const cableIntervalComp = ref();
const routeIntervalComp = ref();

onMounted(async () => {
  const assumptions = await axios({
    method: 'get',
    url: '/api/usecase-2/investment-assumptions/',
  });
  const battery = assumptions.data.AssumptionsBattery;
  batteryInvestPerModule.value = battery.battery_investment_cost_eur;
  batteryKw.value = battery.battery_kw;
  batteryKwh.value = battery.battery_kwh;

  const pv = assumptions.data.AssumptionsInvestmentPv.sort(
    (a, b) => a.min_power_kw - b.min_power_kw,
  );
  const transformerStation =
    assumptions.data.AssumptionsInvestmentTransformerStation.sort(
      (a, b) => a.min_power_kw - b.min_power_kw,
    );
  const cable = assumptions.data.AssumptionsInvestmentCable.sort(
    (a, b) => a.min_power_kw - b.min_power_kw,
  );
  const route = assumptions.data.AssumptionsPerRouteCategory.sort(
    (a, b) => a.min_power_kw - b.min_power_kw,
  );

  intervalPv.value = initIntervalDataSingle(
    pv,
    pvIntervalComp.value,
    'min_power_kw',
    'investment_cost_eur_kw',
  );
  intervalTransformer.value = initIntervalDataSingle(
    transformerStation,
    transformerIntervalComp.value,
    'min_power_kw',
    'investment_cost_eur_kw',
  );
  intervalCable.value = initIntervalDataSingle(
    cable,
    cableIntervalComp.value,
    'min_power_kw',
    'investment_cost_eur_kw',
  );
  intervalRoute.value = initIntervalDataMulti(
    route,
    routeIntervalComp.value,
    'min_power_kw',
    routeCategories.value,
    'route_category',
  );
});

/**
 * Initializes interval data for a single investment component.
 * @param {Array} intervalData - The data to initialize intervals from.
 * @param {Object} childRef - The reference to the child component for creating intervals.
 * @param {string} minFieldName - The field name representing the minimum power.
 * @param {string} valueFieldName - The field name representing the investment cost.
 * @returns {Array} - An array of initialized interval instances.
 */
function initIntervalDataSingle(
  intervalData,
  childRef,
  minFieldName,
  valueFieldName,
) {
  const intervals = [];
  if (intervalData.length) {
    for (const [index, interval] of intervalData.entries()) {
      let to = '∞';
      if (index !== intervalData.length - 1) {
        to = intervalData[index + 1][minFieldName];
      }
      intervals.push(
        childRef.createIntervalInstance(
          interval[minFieldName],
          to,
          interval[valueFieldName],
        ),
      );
    }
  } else {
    intervals.push(childRef.createIntervalInstance(0, '∞'));
  }
  return intervals;
}

function getLabels(categories) {
  const labels = {};
  for (const category of categories) {
    labels[category.choice] = category.label;
  }
  return labels;
}

/**
 * Initializes interval data for multiple investment categories.
 * @param {Array} intervalData - The data to initialize intervals from.
 * @param {Object} childRef - The reference to the child component for creating intervals.
 * @param {string} minFieldName - The field name representing the minimum power.
 * @param {Array} categories - The available categories for the intervals.
 * @param {string} categoryFieldName - The field name representing the category.
 * @returns {Array} - An array of initialized interval instances for multiple categories.
 */
function initIntervalDataMulti(
  intervalData,
  childRef,
  minFieldName,
  categories,
  categoryFieldName,
) {
  // If intervalData is empty, return a default interval
  if (!intervalData.length) {
    return [
      childRef.createIntervalInstance({
        from: 0,
        to: '∞',
      }),
    ];
  }
  const intervals = [];
  // get labels by choice as obj
  const labels = getLabels(categories);
  // add labels to data in order to show in child components input fields
  for (const interval of intervalData) {
    interval.label = labels[interval[categoryFieldName]];
    interval.choice = interval[categoryFieldName];
  }
  // Get unique minimum values
  const uniqueMinValues = Array.from(
    new Set(intervalData.map((e) => e[minFieldName])),
  );
  for (const [index, minValue] of uniqueMinValues.entries()) {
    let to = '∞';
    if (index !== uniqueMinValues.length - 1) to = uniqueMinValues[index + 1];
    const valuesForMin = intervalData.filter(
      (e) => e[minFieldName] === minValue,
    );
    const interval = childRef.createIntervalInstance({
      from: minValue,
      to,
      categories: valuesForMin,
    });
    intervals.push(interval);
  }
  return intervals;
}

/**
 * Adds a new interval to the provided intervals array.
 * @param {Array} intervals - The array of existing intervals.
 * @param {Object} payload - The payload containing the new interval data.
 */
function addInterval(intervals, payload) {
  const countIntervals = intervals.length;
  intervals[countIntervals - 1].to = payload.startValue;
  intervals.push(payload.interval);
}

/**
 * Removes the last interval from the provided intervals array.
 * @param {Array} intervals - The array of existing intervals.
 */
function removeInterval(intervals) {
  intervals.pop();
  const countIntervals = intervals.length;
  intervals[countIntervals - 1].to = '∞';
}

/**
 * Saves the current investment assumptions to the API.
 * If the checkbox for single value for routes is active, adjusts the intervals accordingly.
 */
function save() {
  isCreating.value = true;
  // set to single value if checkbox is active
  if (useSingleValueForRoutes.value === true) {
    intervalRoute.value = [
      routeIntervalComp.value.createIntervalInstance({
        from: 0,
        to: '∞',
        value: singleValueForRoutes,
      }),
    ];
  }

  const flattenInterval = (intervals) => {
    const result = [];
    for (const interval of intervals) {
      for (const value of interval.values) {
        const i = { min_power_kw: interval.from };
        i[value.fieldName] = value.value;
        if (value.route_category) {
          i.route_category = value.route_category;
        }
        result.push(i);
      }
    }
    return result;
  };
  const payload = {
    AssumptionsInvestmentPv: flattenInterval(intervalPv.value),
    AssumptionsInvestmentTransformerStation: flattenInterval(
      intervalTransformer.value,
    ),
    AssumptionsInvestmentCable: flattenInterval(intervalCable.value),
    AssumptionsPerRouteCategory: flattenInterval(intervalRoute.value),
    AssumptionsBattery: {
      battery_kw: batteryKw.value,
      battery_kwh: batteryKwh.value,
      battery_investment_cost_eur: batteryInvestPerModule.value,
    },
  };
  axios({
    method: 'POST',
    url: '/api/usecase-2/investment-assumptions/',
    data: payload,
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
  })
    .then(() => {
      showToast({
        color: 'success',
        message: 'Erfolgreich geändert',
      });
      saveDialog.value = false;
      isCreating.value = false;
    })
    .catch((e) => {
      showToast({
        color: 'error',
        message:
          'Die Operation war nicht erfolgreich. Bitte überprüfen Sie Ihre Eingabe oder wenden Sie sich an den Support',
      });
      saveDialog.value = false;
      isCreating.value = false;
    });
}
</script>
