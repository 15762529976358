<template>
  <div
    :class="{ 'left-5': !showMainMenu, 'left-[400px]': showMainMenu }"
    class="bg-white rounded-[8px] top-[80px] h-fit w-[360px] absolute z-10 p-2.5 flex flex-col gap-2.5 standard-elevation-2 overflow-y-auto hide-scrollbar"
  >
    <h5 class="text-title-neutral">Projektgebiet anlegen</h5>

    <FormValidator v-model="isProjectNameValid">
      <InputEl
        v-model="projectName"
        label="Bezeichnung"
        placeholder="Bitte eingeben"
        data-test="location-name"
        :rules="{ unique: [potentialsNames], required: true }"
      />
    </FormValidator>

    <div
      class="flex flex-col gap-2.5 standard-elevation-0-dark p-2.5 rounded-[6px] mt-2"
    >
      <h5 class="text-color1 flex items-center">Flurstücke selektieren</h5>

      <div
        class="text-neutral flex flex-col items-center gap-0.5 standard-elevation-0-dark rounded-xs p-1.5"
      >
        <span class="caption-1">Anzahl Flurstücke</span>
        <h6>
          {{
            estateIdsSelected.size
              ? estateIdsSelected.size.toLocaleString('de-DE')
              : 0
          }}
        </h6>
      </div>

      <div
        class="text-neutral flex flex-col items-center gap-0.5 standard-elevation-0-dark rounded-xs p-1.5"
      >
        <span class="caption-1">Amtliche Fläche</span>
        <h6>
          {{
            estatesSelectedArea
              ? estatesSelectedArea.toLocaleString('de-DE')
              : 0
          }}
          m²
        </h6>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-2.5">
      <ButtonEl
        :full-width="true"
        color="color2"
        text="Abbrechen"
        @click="abort"
      />

      <ButtonEl
        :full-width="true"
        text="Konfiguration"
        icon="settings_input_component"
        :icon-right="true"
        :disabled="
          !potentialsData ||
          !isProjectNameValid ||
          !metaData ||
          !estateIdsSelected.size
        "
        class="pr-4"
        @click="showConfigDialog = true"
      />

      <VuetifyDialog
        v-model="showConfigDialog"
        :full-screen="true"
        :persistent="true"
        :hide-close-button="true"
        title="Konfiguration der Bottom-Up-Potenzialanalyse"
      >
        <template #content>
          <div class="flex flex-col gap-5">
            <div class="flex gap-2.5 items-end">
              <DropDown
                v-model="selectedPotential"
                label="Voreinstellung"
                placeholder="Bitte wählen"
                :items-data="potentialsData"
                items-data-key="title"
                value="id"
                class="min-w-[250px] w-fit"
              />

              <div
                v-if="selectedPotential"
                class="caption-1 bg-fill-success text-spot-success p-2.5 flex gap-1.5 justify-start items-center h-10"
              >
                <IconWrapper icon="info" />
                <span class="pt-0.5">{{ messageForNewSet }}</span>
              </div>
            </div>

            <PotentialAnalysisTabsWrapper
              v-if="selectedPotential"
              :form-data="{ id: selectedPotential, title: projectName }"
              :hide-tab-at-index="0"
              :meta-data
              :tabs-window-height-substract="335"
              @update:potential-analysis="potentialAnalysis = $event"
              @update:user-select="userSelect = true"
            />
          </div>
        </template>
        <template #actions>
          <div class="flex gap-2.5 justify-between w-full">
            <ButtonEl
              text="Speichern & Berechnung starten"
              icon="table_view"
              class="pl-4"
              :disabled="!selectedPotential"
              :is-loading="loading"
              @click="save"
            />
            <ButtonEl
              text="Abbrechen"
              variant="secondary"
              color="color2"
              @click="showAbortDialog = true"
            />
          </div>
        </template>
      </VuetifyDialog>
    </div>
  </div>

  <!-- Abort Dialog -->
  <VuetifyDialog
    v-if="showAbortDialog"
    v-model="showAbortDialog"
    :persistent="true"
    :hide-close-button="true"
    title="Konfiguration wirklich abbrechen?"
  >
    <template #content>
      <span>Die editierte Potenzialanalyse wird nicht gespeichert.</span>
    </template>
    <template #actions>
      <ButtonEl
        text="Zurück"
        color="color2"
        variant="secondary"
        @click="showAbortDialog = false"
      />
      <ButtonEl text="Abbrechen bestätigen" color="warning" @click="abort()" />
    </template>
  </VuetifyDialog>
</template>

<script setup>
import { onMounted, onUnmounted, ref, toRefs, watch } from 'vue';
import { draw } from '@/apps/features/map/draw/draw';
import InputEl from '@/components/input/InputEl.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import PotentialAnalysisTabsWrapper from '../potential-analysis/components/PotentialAnalysisTabsWrapper.vue';
import { useRestrictionsStore } from '../potential-analysis/stores/potentialAnalysisRestrictionsStore';
import DropDown from '@/components/DropDown/DropDown.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { usePotentialAnalysis } from '../potential-analysis/composables/use-potential-analysis';
import { useToastStore } from '@/stores/toast-store';
import FormValidator from '@/components/FormValidator.vue';

const props = defineProps({
  mapStore: {
    type: Object,
    required: true,
  },
});

const { showMainMenu, estateIdsSelected, estatesSelectedArea } = toRefs(
  props.mapStore,
);
const { resetMapAction } = props.mapStore;

const { showToast } = useToastStore();

showToast({
  color: 'color1',
  message: 'Bitte wählen Sie ein oder mehrere Flurstücke.',
});

const showConfigDialog = ref(false);
const showAbortDialog = ref(false);
const projectName = ref();
const isProjectNameValid = ref(false);
const selectedPotential = ref(null);

const {
  create,
  fetchPotentials,
  fetchMetaData,
  potentialsData,
  potentialAnalysis,
  metaData,
  potentialsNames,
  loading,
} = usePotentialAnalysis();

onMounted(async () => {
  // reset estates
  estateIdsSelected.value = new Set();
  estatesSelectedArea.value = null;

  await fetchPotentials();
  await fetchMetaData();
  await useRestrictionsStore().fetchRestrictions();
});

async function save() {
  potentialAnalysis.value.estates = Array.from(estateIdsSelected.value);
  potentialAnalysis.value.is_manual = true;
  await create(false);
  abort();
}

onUnmounted(() => {
  abort();
});

function abort() {
  showAbortDialog.value = false;
  resetMapAction(draw);
  // delete copied potential analysis
  // mapStore.$reset()
}

const messageForNewSet =
  'Geladene Werte können angepasst werden. Dabei wird ein neues Set erzeugt.';
</script>
