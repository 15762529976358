<template>
  <div class="w-full h-screen relative flex">
    <LayerPanel
      v-if="loaded && !mapStore.searchResults"
      v-show="mapStore.showMainMenu"
      :map-store
    />
    <div class="mapView h-full relative w-full">
      <PvProjectForm v-if="mapStore.showCustomPvProjectForm" :map-store />
      <MeasuringToolForm v-if="mapStore.showMeasureToolForm" />
      <ExternalProjectForm v-if="mapStore.showExternalProjectForm" />
      <ClusterForm v-if="mapStore.showClusterForm" />
      <template v-if="loaded">
        <BaseMap
          :map-store
          :selected-analysis-id="1"
          :layer-config="LayerConfig"
          :panel-objects="panelObjects"
          :fly-to-function="flyToBBox"
        />
        <div class="top-controls absolute w-full">
          <ControlBar :map-store />
          <LegendControls
            :map-store
            :show-legend="showLegend"
            @toggle-legend-state="showLegend = !showLegend"
          />
        </div>
        <div class="bottom-controls absolute w-full">
          <MapControls :map-store v-bind="$attrs" />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import LayerPanel from '@/apps/features/map/layer-panel/LayerPanel.vue';
import LayerConfig from '@/apps/usecase-2/map/layer-config';
import BaseMap from '@/apps/features/map/BaseMap.vue';
import ControlBar from '@/apps/features/map/control-options/components/ControlBar.vue';
import LegendControls from '@/apps/features/map/control-options/components/LegendControls.vue';
import MapControls from '@/apps/features/map/control-options/components/MapControls.vue';
import { panelObjects } from '@/apps/usecase-2/map/info-panel/Info-panel-objects';
import { fitBBox } from '@/mapbox/main';
import PvProjectForm from '@/apps/usecase-2/map/PvProjectForm.vue';
import MeasuringToolForm from '@/apps/usecase-2/map/MeasuringToolForm.vue';
import ExternalProjectForm from '@/apps/usecase-2/map/ExternalProjectForm.vue';
import ClusterForm from '@/apps/usecase-2/map/ClusterForm.vue';
import { useMapStore } from './map-store';

const mapStore = useMapStore();

const showLegend = ref(false);
const loaded = ref(false);

initializeMap();

function initializeMap() {
  mapStore.resetConfigs();
  animateControls();
  loaded.value = true;
  mapStore.initializeMapActions();
}

async function flyToBBox() {
  // const resp = await axios({
  //   method: 'GET',
  //   url: `/api/usecase-2/initial-map-bbox/${400}/`,
  // });
  // const bbox = resp.data.bbox;

  // stuttgart
  const bbox = [9.15, 48.75, 9.25, 48.85];
  fitBBox(bbox);
}

function animateControls() {
  setTimeout(() => {
    const bottomControls = document.querySelector('.bottom-controls');
    bottomControls.classList.add('bottom-controls-active');
    const topControls = document.querySelector('.top-controls');
    topControls.classList.add('top-controls-active');
  }, 500);
}
</script>

<style lang="scss" scoped>
.bottom-controls {
  bottom: -100px;
  transition: bottom 1s ease;
}

.bottom-controls-active {
  bottom: 0;
}

.top-controls {
  top: -100px;
  transition: top 1s ease;
}

.top-controls-active {
  top: 20px;
}
</style>
