import baseData from '@/apps/usecase-2/map/layer-config/base-data';
import externalProject from '@/apps/usecase-2/map/layer-config/external-project';
import markerIcon from '@/assets/img/mapIcons/marker.png';

export default {
  ...baseData,
  ...externalProject,
  marker: {
    layoutState: 'default',
    visible: true,
    layerConfig: {
      geoJSONSource: true,
      type: 'symbol',
      id: 'markerSource',
      layerId: 'marker',
      visibility: 'visible',
      dependsOnScenario: false,
      icons: [
        {
          name: 'markerIcon',
          path: markerIcon,
        },
      ],
    },
    style: {
      default: {
        layout: {
          'icon-image': 'markerIcon',
          'icon-size': 0.1,
          'icon-offset': [0, -165],
        },
      },
    },
  },
};
