<template>
  <div
    :class="{ 'left-5': !getMainMenuShow, 'left-[400px]': getMainMenuShow }"
    class="bg-white rounded-[8px] top-[85px] h-fit w-[360px] absolute z-10 p-2.5 flex flex-col gap-4 standard-elevation-1 overflow-y-auto hide-scrollbar"
  >
    <h4 class="text-infra-highlight-500">Messwerkzeug</h4>

    <!-- Conditional Display Based on Measurement Values -->
    <div v-if="mapStore.measureToolValue" class="flex flex-col gap-3">
      <div class="flex gap-2 items-end justify-between">
        {{ mapStore.measureToolValue }}
        <IconWrapper
          icon="content_copy"
          class="mb-1 cursor-pointer"
          fill="blue-grey-500"
          @click="copyToClipboard(mapStore.measureToolValue)"
        />
      </div>
    </div>
    <div v-else class="body-3">
      Entfernungen und Flächen können gemessen werden, indem eine Strecke oder
      Fläche auf der Karte durch Klicken erzeugt wird.
    </div>
    <ButtonEl :full-width="true" text="Schließen" @click="exitMeasure()" />
  </div>
</template>

<script setup>
import { draw } from '@/apps/features/map/draw/draw';
import { computed } from 'vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { useMapStore } from '@/apps/usecase-2/map/map-store';

const mapStore = useMapStore();

const getMainMenuShow = computed(() => mapStore.showMainMenu);

function copyToClipboard(value) {
  navigator.clipboard.writeText(value);
}

function exitMeasure() {
  mapStore.resetMapAction(draw);
}
</script>
