<template>
  <TopHeaderBar class="justify-start">
    <PotentialAnalysisCreateDialog
      :potentials-data
      @create-analysis="toggleForm"
  /></TopHeaderBar>

  <div class="flex items-center gap-2.5">
    <IconWrapper
      v-if="IconAnalysis"
      :size="48"
      :icon="IconAnalysis"
      fill="text-core-color2"
    />
    <h1 class="text-title-neutral">Potenzialanalyse</h1>
  </div>
  <div class="flex flex-col gap-5">
    <div class="flex flex-col gap-5">
      <VuetifyDataTable
        :headers="tableHeadersPotentials"
        :item-data="sortedPotentialsData"
        :fixed-header="true"
        :substract-height="426"
        :show-expand="false"
      >
        <template #created_at="{ item }">
          {{
            new Date(item.created_at).toLocaleDateString('de-DE') +
            ' von ' +
            item.created_by
          }}
        </template>
        <template #progress>
          <div class="flex justify-center items-center">
            <IconWrapper
              :icon="true ? 'task_alt' : IconTaskProgressSharp"
              :fill="true ? 'text-spot-success' : 'text-spot-warning'"
            />
          </div>
        </template>
        <template #functions="{ item }">
          <div class="flex items-center justify-end gap-2">
            <DeleteDialog
              :id="item.id"
              :name-deleted-item="item.title"
              :dto="PotentialAnalysisDto"
              @instance-removed="fetchPotentials(item.id)"
            >
              <IconWrapper
                fill="text-core-color1"
                hover="hover:text-button-primary-hover-color1"
                icon="delete_forever"
                class="cursor-pointer"
                type="round"
              />
            </DeleteDialog>
            <IconWrapper
              icon="segment"
              fill="text-core-color1"
              hover="hover:text-button-primary-hover-color1"
              class="cursor-pointer"
              @click="toggleForm(item.id)"
            />
          </div>
        </template>
      </VuetifyDataTable>
    </div>
    <div
      v-if="showForm"
      class="bg-white rounded-[8px] standard-elevation-0-dark p-5 flex flex-col gap-5"
    >
      <div class="flex justify-between items-center gap-5 min-h-[48px]">
        <h3 class="text-title-neutral">Konfiguration Potenzialanalyse</h3>
        <div class="flex items-center gap-5">
          <ButtonEl
            v-if="!readOnly"
            text="Berechnung starten"
            icon="table_view"
            :disabled="
              !formIsValid ||
              !titleIsUnique ||
              !potentialAnalysis.municipalities.length ||
              !isClusterAnalysisComplete
            "
            :is-loading="loading"
            @click="create"
          />
          <IconWrapper
            icon="close"
            class="cursor-pointer"
            fill="text-core-color1"
            @click="closeForm"
          />
        </div>
      </div>
      <PotentialAnalysisTabsWrapper
        ref="formTabsEl"
        :potentials-data
        :form-data
        :potentials-names
        :meta-data
        @update:is-valid="formIsValid = $event"
        @update:read-only="readOnly = $event"
        @update:title-is-unique="titleIsUnique = $event"
        @update:potential-analysis="potentialAnalysis = $event"
      />
    </div>
  </div>
</template>

<script setup>
import { tableHeadersPotentials } from '@/apps/usecase-2/potential-analysis/potential-analysis-data';
import { PotentialAnalysisDto } from '@/apps/usecase-2/potential-analysis/potential-analysis-dto';
import IconAnalysis from '@/assets/icons/custom/misc/IconAnalysis.vue';
import { useBaseStore } from '@/apps/basemodule/base-store';
import ButtonEl from '@/components/button/ButtonEl.vue';
import DeleteDialog from '@/components/deleteDialog/DeleteDialog.vue';
import TopHeaderBar from '@/components/header-component/TopHeaderBar.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import VuetifyDataTable from '@/components/vuetifyDataTable/VuetifyDataTable.vue';
import { computed, ref } from 'vue';
import PotentialAnalysisCreateDialog from './components/PotentialAnalysisCreateDialog.vue';
import PotentialAnalysisTabsWrapper from './components/PotentialAnalysisTabsWrapper.vue';
import { useRestrictionsStore } from './stores/potentialAnalysisRestrictionsStore';
import { usePotentialAnalysis } from './composables/use-potential-analysis';

const formIsValid = ref(false);
const titleIsUnique = ref(true);
const readOnly = ref(false);
const formTabsEl = ref(null);

const {
  create,
  fetchPotentials,
  fetchMetaData,
  loading,
  showForm,
  potentialsData,
  potentialAnalysis,
  metaData,
  potentialsNames,
  isClusterAnalysisComplete,
} = usePotentialAnalysis();

await fetchPotentials();
await useRestrictionsStore().fetchRestrictions();
await useBaseStore().fetchSimplifiedMuniData();
await fetchMetaData();

const formData = ref({
  id: null,
  title: null,
});

function toggleForm(id = null, title = null) {
  showForm.value = true;
  formData.value = { id, title };
}

function closeForm() {
  showForm.value = false;
}

const sortedPotentialsData = computed(() => {
  return [...potentialsData.value].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at),
  );
});
</script>
