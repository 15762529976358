import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useToastStore = defineStore('toast', () => {
  const toast = ref({
    multiLine: false,
    show: false,
    color: 'black',
    message: '',
    timeout: 3000,
    outlined: false,
    textColor: null,
  });

  function showToast(toastOptions) {
    toast.value = {
      ...toast.value,
      ...toastOptions,
      show: true,
    };
  }

  return { toast, showToast };
});
