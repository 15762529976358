import mapboxgl from 'mapbox-gl';
import U from 'map-gl-utils';
import config from '@/configs';
import onLeftClick from './onClick/onclick-main';
import { createLayers, setLayerHierarchy } from './createLayer';
import { initClicked, initMarked } from './onClick/constructorClick';
import { addDraw, getDrawFeatures } from '@/apps/features/map/draw/draw';

mapboxgl.accessToken =
  'pk.eyJ1IjoibWFwZnJlZWxiZCIsImEiOiJjandzem95eW8wa2FhM3lxdjdxdjNlbjliIn0.JVEcWEoquKW1_4p6hBPTNg';

let map;

export function flyTo(configFlyTo, targetMap = map) {
  targetMap.flyTo({
    zoom: configFlyTo.zoom,
    center: [configFlyTo.lng, configFlyTo.lat],
    essential: true,
    maxZoom: configFlyTo.maxZoom,
  });
}

export function getMap() {
  return map;
}

export function setMaxZoom(
  maxZoom = config.constraints.mapView.mapConstraints.maxZoom,
) {
  map.setMaxZoom(maxZoom);
}

export function getZoom() {
  return map.getZoom();
}

function create3dLayers(map_) {
  map_.addSource('mapbox-dem', {
    type: 'raster-dem',
    url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
    tileSize: 512,
    maxzoom: 14,
  });
  map_.addLayer({
    id: 'sky',
    type: 'sky',
    paint: {
      // set up the sky layer to use a color gradient
      'sky-type': 'gradient',
      // the sky will be lightest in the center and get darker moving radially outward
      // this simulates the look of the sun just below the horizon
      'sky-gradient': [
        'interpolate',
        ['linear'],
        ['sky-radial-progress'],
        0.8,
        'rgba(135, 206, 235, 1.0)',
        1,
        'rgba(0,0,0,0.1)',
      ],
      'sky-gradient-center': [0, 0],
      'sky-gradient-radius': 90,
      'sky-opacity': [
        'interpolate',
        ['exponential', 0.1],
        ['zoom'],
        5,
        0,
        22,
        1,
      ],
    },
  });
}

export function setPitch(pitch) {
  if (pitch === '3D') {
    map.easeTo({ pitch: 60 });
    const terrainmethods = { source: 'mapbox-dem', exaggeration: 1.5 };
    map.setTerrain(terrainmethods);
    return '2D';
  }
  map.setTerrain();
  map.easeTo({ pitch: 0 });
  return '3D';
}

export function zoomMap() {
  map.easeTo({
    zoom: map.getZoom() + 0.5,
    duration: 750,
  });
}

export function unzoomMap() {
  map.easeTo({
    zoom: map.getZoom() - 0.5,
    duration: 750,
  });
}

export function compassMap() {
  const methods = { bearing: 0 };
  map.easeTo(methods);
}

export function removeMarker(targetMap = map) {
  if (targetMap && targetMap.getSource('markerSource')) {
    targetMap.getSource('markerSource').setData({
      type: 'FeatureCollection',
      features: [],
    });
  }
}

export function setMarker(coords, targetMap = map) {
  removeMarker(targetMap);
  targetMap.getSource('markerSource').setData({
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: coords,
        },
      },
    ],
  });
  targetMap.moveLayer('marker');
}

export function drawChangeMode(draw, mode, opts) {
  draw.changeMode(mode, opts);
  getMap().fire('draw.modechange', { mode });
}

/**
 * fit map to boundingbox
 * @param  {Array} bbox
 */
export function fitBBox(bbox) {
  map.fitBounds(bbox);
}

/**
 * set visibility (usually changed through switch in panel 3).
 * @param  {array} layers get all layers and corresponding layer states and visibility
 */
export function setVisibility(layers) {
  for (let i = 0, len = layers.length; i < len; i += 1) {
    if (layers[i].visible) map.U.show(layers[i].layer);
    else map.U.hide(layers[i].layer);
  }
}

/**
 * Change source layer and rerender all layers bound to the source layer
 * @param  {string}  sourceLayer source layer
 * @param  {Object} scenarioIds obj containing previous and new scanrio Ids
 */
export function changeSourceLayer(sourceLayer, scenarioIds) {
  const sourceLayerNew = `${scenarioIds.new}_${sourceLayer}`;
  const sourceLayerPrevious = `${scenarioIds.previous}_${sourceLayer}`;
  const foundLayer = map
    .getStyle()
    .layers.filter((e) => e['source-layer'] === sourceLayerPrevious);
  if (foundLayer.length === 0) return;
  map.U.addVector(
    foundLayer[0].source,
    `${window.TILESERVER_URL}/ts/tiles/${sourceLayerNew}/{z}/{x}/{y}.pbf`,
  );
  for (let i = 0, len = foundLayer.length; i < len; i += 1) {
    map.removeLayer(foundLayer[i].id);
    foundLayer[i]['source-layer'] = sourceLayerNew;
    map.addLayer(foundLayer[i]);
  }
  setLayerHierarchy(map);
}

export function setGeoJsonData(
  sourceLayer,
  featureCollection,
  layer = null,
  move = false,
) {
  map.U.setData(sourceLayer, featureCollection);
  if (move) map.moveLayer(layer);
}

/**
 * set layout depending on layer states (usually changed through radio in panel 3). The state
 * is being watched and whenever any state changes, the layout is set for all visible layers.
 * @param  {array} layers get all layers and corresponding layer states
 * @param  {object} layerConfig man layer layer-panel
 * @param  {string} year year selected in slider
 */
export function setStyle(layers, layerConfig, year) {
  for (let i = 0, len = layers.length; i < len; i += 1) {
    if (layers[i].visible) {
      let style = layerConfig[layers[i].layer].style[layers[i].state];
      if (typeof style === 'function') style = style(year);
      map.U.setProperty(layers[i].layer, style);
    }
  }
}

/**
 * set filter (usually changed through radio in panel 3). The state
 * is being watched and whenever any state changes, the filters are set.
 * @param  {String} layerKey
 * @param  {Array} filter
 */
export function setFilter(layerKey, filter) {
  if (typeof map.style === 'undefined') return;
  map.setFilter(layerKey, filter);
}
let styleLoaded = false;
/**
 * initialize map on URL load.
 * @param  {object} mapStore pinia map store
 * @param  {object} methods methods in map
 * @param  {object} layers get all layers and corresponding layer states
 * @param  {number} getYear year selected in slider
 * @param  {string} selectedAnalysisId scenario selected
 */
export function createMap(
  mapStore,
  methods,
  layers,
  getYear,
  selectedAnalysisId,
) {
  map = new mapboxgl.Map(config.constraints.mapView.mapConstraints);
  map.addControl(new mapboxgl.AttributionControl(), 'bottom-left');
  U.init(map, mapboxgl);
  addDraw(map);
  initMarked(map);
  const clicked = initClicked(map);
  const getLayersToClick = mapStore.getLayersToClick;
  map.on('load', () => {
    create3dLayers(map);
    createLayers(
      map,
      layers,
      setGeoJsonData,
      mapStore,
      getYear,
      selectedAnalysisId,
    );
    methods.initialMapStyleLoaded(true);
    methods.applyFilters(mapStore.getAppliedFilters);
    methods.flyToFunction();
    // set layer and style accordingly to custom config option and reset
    for (const layer of mapStore.customMapConfig) {
      mapStore.changeLayerVisibility({ onLayers: [layer.layer], active: true });
      mapStore.changeLayerState({
        layerKeys: [layer.layer],
        layerState: layer.state,
      });
    }
    // init draw events and store
    mapStore.configureInitialMapState([]);
    map.on('draw.modechange', (e) => {
      mapStore.changeDrawMode(e.mode);
    });
    map.on('draw.create', (e) =>
      mapStore.updateDrawFeatures(getDrawFeatures()),
    );
    map.on('draw.delete', (e) =>
      mapStore.updateDrawFeatures(getDrawFeatures()),
    );
    map.on('draw.update', (e) =>
      mapStore.updateDrawFeatures(getDrawFeatures()),
    );
    styleLoaded = true;
  });

  map.on('click', (e) => {
    if (!styleLoaded) return;
    // Check if we're in a specific draw mode
    if (mapStore.drawModeActive !== 'simple_select') return;
    removeMarker();
    onLeftClick(map, e, methods, clicked, getLayersToClick, mapStore);
  });

  map.on('zoom', () => {
    // insert some action
  });
  return map;
}

export function toggleSatellite(isActive) {
  const satLayerId = 'satellite';
  if (!isActive) {
    map.removeLayer(satLayerId);
    map.removeSource(satLayerId);
  } else {
    map.addLayer({
      id: satLayerId,
      source: {
        type: 'raster',
        url: 'mapbox://mapbox.satellite',
        tileSize: 256,
      },
      type: 'raster',
      visibility: false,
      style: null,
    });
    setLayerHierarchy(map);
  }
}

export function destroyMap() {
  map.remove();
}
