import IconUrbaneStandorte from '@/assets/icons/custom/misc/IconUrbaneStandorte.vue';
import IconEnergieEffizienz from '@/assets/icons/custom/misc/IconEnergieEffizienz.vue';
import IconDevelopment from '@/assets/icons/custom/misc/IconDevelopment.vue';
import IconZahnrad from '@/assets/icons/custom/misc/IconZahnrad.vue';

const useCase2CardData = [
  {
    title: 'Kartenansicht',
    id: 'mapView2',
    description: 'Geografische Verortung der Analysen in der Kartenansicht.',
    icon: IconUrbaneStandorte,
    row: 1,
    order: 1,
    routeName: 'mapView2',
  },
  {
    title: 'Auswertung',
    id: 'evaluation',
    description:
      'Grafische Auswertungen zu Bestands- und ' +
      'Potenzialanalyse sowie zu Zielszenarien.',
    icon: IconEnergieEffizienz,
    row: 1,
    order: 2,
    routeName: 'UseCase2ResultsPage',
  },
  {
    title: 'Potenzialanalyse',
    id: 'potential',
    description:
      'Grafische Auswertungen zu Bestands- und ' +
      'Potenzialanalyse sowie zu Zielszenarien.',
    icon: IconDevelopment,
    row: 2,
    order: 1,
    routeName: 'UseCase2PotentialPage',
  },
  {
    title: 'Einstellungen',
    id: 'assumptions-general',
    description:
      'Grafische Auswertungen zu Bestands- und ' +
      'Potenzialanalyse sowie zu Zielszenarien.',
    icon: IconZahnrad,
    row: 2,
    order: 2,
    routeName: 'UseCase2SettingsPage',
  },
];

export { useCase2CardData };
