<template>
  <TopHeaderBar />
  <div class="flex flex-col gap-5">
    <div class="flex items-center gap-4">
      <IconWrapper
        :size="64"
        :icon="IconEnergieEffizienz"
        fill="text-core-color2"
      />
      <h1 class="text-title-neutral">Auswertung</h1>
    </div>
    <div
      class="flex flex-col gap-5 bg-white rounded-xl p-5 standard-elevation-2 min-h-[calc(100vh-374px)]"
    >
      <div class="flex gap-2.5">
        <FormTabs
          :disable-transitions="true"
          :prevent-window-scroll="true"
          :tabs="tabs"
          :hide-tab-windows="true"
          @updated:tab="tab = $event.id"
        />
        <ButtonEl
          icon="filter_alt"
          color="color2"
          variant="secondary"
          @click="slideContainerOpen = true"
        />
      </div>
      <div class="flex flex-col justify-between w-full gap-5">
        <div v-auto-animate class="flex flex-col gap-2.5">
          <div
            v-for="row in filteredConfig"
            :key="row"
            class="grid grid-flow-col auto-cols-fr gap-2.5"
          >
            <div
              v-for="item in row"
              :key="item"
              class="bg-white standard-elevation-0-dark rounded-lg p-2.5 flex items-center w-full body-1 gap-3"
            >
              <IconWrapper
                :size="32"
                :icon="item.icon"
                fill="text-title-neutral"
              />
              <div class="flex items-center justify-between w-full">
                <span class="pt-0.5 text-neutral">{{ item.label }}</span>
                <h4 class="text-title-neutral">{{ item.value }}</h4>
              </div>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-between gap-2.5">
          <InputEl
            :placeholder="
              tab === 1
                ? 'Nach Cluster oder Gemeinde suchen'
                : 'Nach Einzelprojekt oder Gemeinde suchen'
            "
            class="w-1/3"
            :suffix-icon="true"
            suffix="search"
            :suffix-left="true"
          />

          <ButtonEl
            icon="map"
            color="color2"
            variant="secondary"
            @click="slideContainerOpen = true"
          />
        </div>

        <div class="grid auto-cols-auto grid-flow-col relative mb-[68px]">
          <VuetifyDataTable
            :total-items="projectData.count"
            :headers="splitTableData.headers.firstTable"
            :item-data="splitTableData.data.firstTable"
            :show-expand="false"
            :hide-footer="true"
            :fixed-header-height="108"
            :fixed-row-height="64"
            :set-page="page"
            :set-items-per-page="itemsPerPage"
            class="rounded-r-none w-[250px] rounded-bl-none"
          />
          <VuetifyDataTable
            :total-items="projectData.count"
            :headers="splitTableData.headers.secondTable"
            :item-data="splitTableData.data.secondTable"
            :show-expand="false"
            :hide-footer="true"
            :fixed-header-height="108"
            :fixed-row-height="64"
            :set-page="page"
            :set-items-per-page="itemsPerPage"
            class="rounded-none w-full overflow-x-auto hide-scrollbar"
          />
          <VuetifyDataTable
            :total-items="projectData.count"
            :headers="splitTableData.headers.thirdTable"
            :item-data="splitTableData.data.thirdTable"
            :show-expand="false"
            :fixed-header-height="108"
            :fixed-row-height="64"
            class="rounded-l-none w-min third-table"
            :prevent-immediate-update-options="true"
            @update:options="getData"
          >
            <template #functions="{ item }">
              <div class="flex justify-end items-center gap-4 w-fit">
                <IconWrapper
                  type="round"
                  :size="32"
                  fill="text-core-color1"
                  hover="hover:text-button-primary-hover-color1"
                  class="cursor-pointer"
                  icon="map"
                  @click="console.log(item)"
                />
                <IconWrapper
                  type="round"
                  :size="32"
                  fill="text-core-color1"
                  hover="hover:text-button-primary-hover-color1"
                  class="cursor-pointer"
                  icon="delete_forever"
                  @click="console.log(item)"
                />
                <IconWrapper
                  type="round"
                  :size="32"
                  fill="text-core-color1"
                  hover="hover:text-button-primary-hover-color1"
                  class="cursor-pointer"
                  icon="arrow_forward"
                  @click="console.log(item)"
                />
              </div>
            </template>
          </VuetifyDataTable>
        </div>

        <div class="flex items-center justify-between gap-2.5">
          <ButtonEl
            text="Liste exportieren"
            icon="download"
            color="color2"
            variant="secondary"
          />
          <h6 v-if="tab === 1" class="text-neutral">
            *Werte in der Tabelle beziehen sich auf den Anteil von PV-Projekten
            der EnBW innerhalb des Clusters.
          </h6>
        </div>
      </div>
    </div>
  </div>

  <SlideContainer v-model="slideContainerOpen" title="Kartenübersicht">
    <!-- Map content goes here -->
  </SlideContainer>
</template>

<script setup>
/*
TODO: Wir sollten hier die pagination mit den requests aus dem Backend
 verknüpfen. Haben wir in den Usecase 1 Ansichten nicht gemacht,
 soweit ich weiß und müssten wir noch nachziehen. Das Filterset im Design
 Müsste sich ebenfalls auf die Ergebnisse auswirken. Die Textsuche nach Namen/
 Gemeinde wird nur auf Ergebnisse im Frontend angewand.

 Es fehlen im Backend: Investkosten, status, Spannungsebene, is in cluster,
 is bottom up
*/
import IconEnergieEffizienz from '@/assets/icons/custom/misc/IconEnergieEffizienz.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import FormTabs from '@/components/formTabs/FormTabs.vue';
import TopHeaderBar from '@/components/header-component/TopHeaderBar.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import InputEl from '@/components/input/InputEl.vue';
import SlideContainer from '@/components/SlideContainer.vue';
import VuetifyDataTable from '@/components/vuetifyDataTable/VuetifyDataTable.vue';
import axios from '@/utils/axiosHelper';
import { ref, computed } from 'vue';

const tabs = [
  {
    title: 'Einzelprojekte',
    icon: 'euro_symbol',
    id: 0,
  },
  {
    title: 'Cluster',
    icon: 'switch_access_shortcut',
    id: 1,
  },
];
const tab = ref(0);

// Reactive config object with nested structure
const config = ref([
  [
    { label: 'Anzahl Cluster', value: 9, icon: 'bubble_chart', tab: 1 },
    {
      label: 'Anzahl Einzelprojekte',
      value: 300,
      icon: 'flip_to_front',
      tab: 1,
    },
  ],
  [
    { label: 'Gesamtfläche (ha)', value: 234, icon: 'select_all' },
    { label: 'Anzahl betroffener Flurstücke', value: 89, icon: 'layers' },
  ],

  [
    {
      label: 'Installierbare Leistung PV (MW)',
      value: 45.23,
      icon: 'solar_power',
    },
  ],

  [
    {
      label: 'Investitionskosten Netzanschluss (Mio. €)',
      value: 73.56,
      icon: 'card_travel',
    },
    {
      label: 'Investitionskosten Anlage (Mio. €)',
      value: 91.78,
      icon: 'euro',
    },
    {
      label: 'Investitionskosten (Mio. €)',
      value: 78.83,
      icon: 'euro',
    },
  ],
]);

const filteredConfig = computed(() => {
  if (tab.value !== 1) {
    // Exclude the first child array to maintain order
    return config.value.slice(1);
  }
  // Return the whole config if tab is not 1
  return config.value;
});

const emit = defineEmits(['loaded']);

const page = ref(1);
const itemsPerPage = ref(10);

const projectData = ref(null);
const loaded = ref(false);

async function getData(options = {}) {
  if (options) {
    page.value = options.page;
    itemsPerPage.value = options.itemsPerPage;
  }

  const params = {
    page: options.page || page.value,
    page_size: options.itemsPerPage || itemsPerPage.value,
  };

  try {
    const response = await axios({
      url: '/api/usecase-2/pv-project/',
      method: 'GET',
      params: params,
    });
    projectData.value = response.data;
    loaded.value = true;
    emit('loaded', loaded.value);
  } catch (error) {
    console.error('Failed to fetch data: ', error);
  }
}

// Initial data fetch
await getData({ page: page.value, itemsPerPage: itemsPerPage.value });

const projectAggregationData = await axios({
  method: 'GET',
  url: '/api/usecase-2/pv-project-aggregated/',
});

const projectAggregationMetaData = await axios({
  method: 'GET',
  url: '/api/usecase-2/pv-project-aggregated-meta-data/',
});

const slideContainerOpen = ref(false);

const splitTableData = computed(() => {
  const projectHeaders = [
    {
      title: 'Potenzialanalyse',
      key: 'name',
      align: 'left',
    },
    {
      title: 'Bezeichnung Fläche',
      key: 'name',
      align: 'left',
      width: '150px',
    },
    {
      title: 'Gemeinde, Landkreis etc.',
      key: 'created_at',
      align: 'left',
      width: '280px',
    },
    {
      title: 'Fläche',
      key: 'potential_area_m2',
      align: 'end',
      width: '150px',
    },
    {
      title: 'Flur-\nstücke',
      key: 'count_estates',
      align: 'end',
      width: '120px',
    },
    {
      title: 'Leistung\nPV',
      key: 'power_kw',
      align: 'end',
      width: '150px',
    },
    {
      title: 'Länge Netz\nanschluss',
      key: 'length_m',
      align: 'end',
      width: '150px',
    },
    {
      title: 'Leistung\nBatteriesp.',
      key: 'battery_capacity_kw',
      align: 'end',
      width: '150px',
    },
    {
      title: 'Spezifische\nInvestition',
      key: '',
      align: 'right',
      width: '150px',
    },
    {
      title: 'Spannungs-\nebene',
      key: '',
      align: 'right',
      width: '150px',
    },
    {
      title: 'Investitions-\nvolumen',
      key: '',
      align: 'right',
    },
    {
      title: 'Wert-\nhaltigkeit',
      key: '',
      align: 'center',
      width: '150px',
    },
    {
      title: '',
      key: 'review_state',
      width: '120px',
      align: 'center',
    },
    {
      title: '',
      key: 'functions',
      width: '160px',
      align: 'end',
      sortable: false,
    },
  ];

  const clusterHeaders = [
    {
      title: 'Potenzialanalyse',
      key: 'name',
      width: '150px',
      align: 'left',
    },
    {
      title: 'Anzahl Einzelprojekte',
      key: 'name',
      align: 'left',
      width: '150px',
    },
    {
      title: 'Gemeinde, Landkreis etc.',
      key: 'created_at',
      width: '280px',
      align: 'left',
    },
    {
      title: 'Fläche',
      key: 'potential_area_m2',
      align: 'end',
      width: '150px',
    },
    {
      title: 'Flur-\nstücke',
      key: 'count_estates',
      align: 'end',
      width: '120px',
    },
    {
      title: 'Leistung\nPV',
      key: 'power_kw',
      align: 'end',
      width: '150px',
    },
    {
      title: 'Länge Netz\nanschluss',
      key: 'length_m',
      align: 'end',
      width: '150px',
    },
    {
      title: 'Leistung\nBatteriesp.',
      key: 'battery_capacity_kw',
      align: 'end',
      width: '150px',
    },
    {
      title: 'Spezifische\nInvestition',
      key: '',
      align: 'end',
      width: '150px',
    },
    {
      title: 'Spannungs-\nebene',
      key: '',
      align: 'end',
      width: '150px',
    },
    {
      title: 'Investitions-\nvolumen',
      key: '',
      align: 'end',
      width: '150px',
    },
    {
      title: '',
      key: 'review_state',
      width: '80px',
      align: 'center',
    },
    {
      title: '',
      key: 'functions',
      width: '130px',
      align: 'end',
      sortable: false,
    },
  ];

  // Select headers and slice points based on tab
  let currentHeaders, slicePoints;

  if (tab.value === 0) {
    currentHeaders = projectHeaders;
    slicePoints = [1, 11]; // Adjust these values for cluster view
  } else {
    currentHeaders = clusterHeaders;
    slicePoints = [1, 11]; // Adjust these values for project view
  }

  // Get the keys for each section
  const firstTableKeys = currentHeaders
    .slice(0, slicePoints[0])
    .map((h) => h.key);
  const secondTableKeys = currentHeaders
    .slice(slicePoints[0], slicePoints[1])
    .map((h) => h.key);
  const thirdTableKeys = currentHeaders.slice(slicePoints[1]).map((h) => h.key);

  // Create filtered data objects for each table
  const processedData = projectData.value.results.map((item) => {
    const firstTableData = {};
    const secondTableData = {};
    const thirdTableData = {};

    // Distribute properties to appropriate tables
    Object.entries(item).forEach(([key, value]) => {
      if (firstTableKeys.includes(key)) {
        firstTableData[key] = value;
      } else if (secondTableKeys.includes(key)) {
        secondTableData[key] = value;
      } else if (thirdTableKeys.includes(key)) {
        thirdTableData[key] = value;
      }
    });

    // Ensure each data object has an id for Vue's key binding
    firstTableData.id = item.id;
    secondTableData.id = item.id;
    thirdTableData.id = item.id;

    return {
      firstTable: firstTableData,
      secondTable: secondTableData,
      thirdTable: thirdTableData,
    };
  });

  return {
    headers: {
      firstTable: currentHeaders.slice(0, slicePoints[0]),
      secondTable: currentHeaders.slice(slicePoints[0], slicePoints[1]),
      thirdTable: currentHeaders.slice(slicePoints[1]),
    },
    data: {
      firstTable: processedData.map((d) => d.firstTable),
      secondTable: processedData.map((d) => d.secondTable),
      thirdTable: processedData.map((d) => d.thirdTable),
    },
  };
});
</script>

<style scoped lang="scss">
@import '@/assets/styles';

:deep(.third-table) {
  position: unset;
  .vuetify-data-table-footer {
    position: absolute;
    background-color: white;
    right: 0;
    bottom: -68px;
    width: 100%;
    @apply rounded-b-lg;
    @extend .standard-elevation-0-dark;
  }
}
</style>
