<template>
  <div
    :class="{ 'left-5': !getMainMenuShow, 'left-[400px]': getMainMenuShow }"
    class="bg-white rounded-[8px] top-[80px] h-fit w-[360px] absolute z-10 p-2.5 flex flex-col gap-2.5 standard-elevation-2 overflow-y-auto hide-scrollbar"
  >
    <h5 class="text-title-neutral">Externes Projekt anlegen</h5>

    <InputEl
      v-model="projectDto.name"
      label="Bezeichnung"
      placeholder="Bitte eingeben"
      data-test="location-name"
    />
    <InputEl
      v-model="projectDto.project_developer"
      label="Projektentwickler"
      placeholder="Bitte eingeben"
      data-test="project-developer"
    />
    <VRadioGroup v-model="projectDto.type">
      <div class="flex justify-start">
        <VRadio value="PV">
          <template #label>PV</template>
        </VRadio>
        <VRadio value="Wind" class="ml-2">
          <template #label>Wind</template>
        </VRadio>
      </div>
    </VRadioGroup>
    <InputEl
      v-model="projectDto.power_kw"
      label="Leistung"
      placeholder="Bitte eingeben"
      data-test="power"
      input-type="float"
      suffix="kWp"
    />
    <InputEl
      v-model="projectDto.power_connection_kw"
      label="Relevante Anschlussleistung"
      placeholder="Bitte eingeben"
      data-test="power-relevant"
      input-type="float"
      suffix="kW"
    />

    <div class="grid grid-cols-2 gap-2.5">
      <ButtonEl
        :full-width="true"
        color="color2"
        text="Abbrechen"
        @click="abort()"
      />

      <ButtonEl
        :full-width="true"
        text="Speichern"
        :disabled="!isValid"
        class="pr-4"
        @click="save()"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { draw, getDrawFeaturesByType } from '@/apps/features/map/draw/draw';
import InputEl from '@/components/input/InputEl.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import { useMapStore } from '@/apps/usecase-2/map/map-store';
import { ExternalProjectDto } from '@/apps/usecase-2/map/external-project-dto';
import {
  convertMetaDataToFormRules,
  restGetOrFetchMetaData,
  restPost,
} from '@/utils/rest-utils';

const mapStore = useMapStore();

const projectDto = ref(new ExternalProjectDto());
const externalProjectMetaData =
  await restGetOrFetchMetaData(ExternalProjectDto);
// const formRules = convertMetaDataToFormRules(potentialMetaData);

const getMainMenuShow = computed(() => mapStore.showMainMenu);

async function save() {
  const geometries = mapStore.externalProjectPointDraw;
  projectDto.value.geometry = geometries[0].geometry;
  await restPost(projectDto.value, [
    'geometry',
    'name',
    'project_developer',
    'type',
    'power_kw',
    'power_connection_kw',
  ]);
  await mapStore.fetchExternalProjects();
  abort();
}

function abort() {
  mapStore.resetMapAction(draw);
  // mapStore.$reset()
}

const isValid = computed(() => {
  const { name, type, power_kw } = projectDto.value;
  return mapStore.externalProjectPointDraw.length && name && type && power_kw;
});
</script>
