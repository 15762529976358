import { AZUR_BLUE_30 } from '@/configs/color-constants';

export const LAYER_KEY__ESTATE = '276_estate';

export default {
  [LAYER_KEY__ESTATE]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'polygon',
      id: LAYER_KEY__ESTATE,
      sourceLayer: LAYER_KEY__ESTATE,
      layerId: LAYER_KEY__ESTATE,
      visibility: 'none',
      dependsOnScenario: false,
    },
    style: {
      default: {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'marked'], false],
          AZUR_BLUE_30,
          'rgba(255,255,255,0)',
        ],
        fillOpacity: 1,
        fillOutlineColor: [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          AZUR_BLUE_30,
          '#000000',
        ],
      },
    },
  },
};
