<template>
  <Teleport to="body">
    <VDialog
      v-model="dialogState"
      :persistent="persistent"
      :disabled="disabled"
      transition="fade-transition"
      :no-click-animation="true"
      :width="widthCalc"
      :height="fullScreen ? '100%' : 'auto'"
      :content-class="{
        'overflow-hidden rounded-[8px]': overFlowHiddenForScroll,
        'left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] m-0':
          fullScreen,
      }"
      max-height="95vh"
    >
      <template #activator="{ props: activatorProps }">
        <div
          v-bind="activatorProps"
          :class="{
            'w-full static': activatorFullWidth,
            'w-fit': !activatorFullWidth,
            [activatorClass]: activatorClass,
          }"
        >
          <slot name="activatorItem" class="cursor-pointer" />
        </div>
      </template>

      <VCard
        class="standard-elevation-2 rounded-[8px] h-full"
        :class="[
          {
            'overflow-visible': overflowVisible,
          },
          customClasses,
        ]"
      >
        <VCardText class="p-[20px] flex flex-col gap-5 min-w-[500px]">
          <div class="flex align-center justify-between gap-4">
            <h4 class="text-title-neutral whitespace-break-spaces">
              {{ title }}
            </h4>
            <IconWrapper
              v-if="!hideCloseButton"
              icon="close"
              class="cursor-pointer"
              fill="text-core-color1"
              hover="hover:text-button-primary-hover-color1"
              @click="closeDialog"
            />
          </div>
          <div class="flex flex-col gap-5 relative h-full justify-between">
            <div class="min-h-[26px] h-fit">
              <slot name="content" />
            </div>
            <div
              v-if="$slots.actions"
              class="flex items-center justify-end gap-3"
              :class="{ 'justify-center': actionsCentered }"
            >
              <slot name="actions" />
            </div>
          </div>
        </VCardText>
      </VCard>
    </VDialog>
  </Teleport>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import IconWrapper from '../IconWrapper/IconWrapper.vue';

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  activatorFullWidth: {
    type: Boolean,
    default: true,
  },
  activatorClass: {
    type: String,
    default: null,
  },
  overflowVisible: {
    type: Boolean,
    default: false,
  },
  overFlowHiddenForScroll: {
    type: Boolean,
    default: false,
  },
  fullScreen: {
    type: Boolean,
    default: false,
  },
  persistent: {
    type: Boolean,
    default: false,
  },
  actionsCentered: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  hideCloseButton: {
    type: Boolean,
    default: false,
  },
  customClasses: {
    type: String,
    default: null,
  },
  width: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['update:modelValue']);

const dialogState = ref(props.modelValue);
const windowWidth = ref(window.innerWidth);

function closeDialog() {
  dialogState.value = false;
}

onMounted(() => {
  window.addEventListener('resize', () => {
    windowWidth.value = window.innerWidth;
  });
});

const widthCalc = computed(() => {
  if (props.width) {
    return props.width;
  }
  if (props.fullScreen) {
    return '98vw';
  }
  return 'auto';
});

watch(
  () => props.modelValue,
  (newValue) => {
    dialogState.value = newValue;
  },
);

watch(dialogState, (newValue) => {
  emit('update:modelValue', newValue);
});
</script>

<style scoped lang="scss">
.fade-transition {
  transition: all 0.3s ease;
}

.v-overlay__scrim {
  @apply bg-canvas;
  opacity: 0.4;
}

:deep(.v-overlay__content) {
  border-radius: 8px !important;
}
</style>
